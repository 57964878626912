import { useNavigate } from "react-router-dom";
import { SplitColumn, PageDetails, ButtonGroup, InfoPanel } from "layout";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import Button from "components/inputs/button/Button";
import { H1, H2 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import ConsentForm, { ConsentFormSubmitCallback } from "features/forms/consent-form/ConsentForm";
import { DTOPageEnum } from "constants/pageNames";
import { ConsentContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";

const pageName = DTOPageEnum.enum.consent;

const Consent = () => {
    usePageTitle("Consent | AAA Life");
    useSendInteraction(pageName);

    const navigate = useNavigate();
    const { state, dispatch } = useFormState(pageName);

    // FUTURE: Add state validation here
    const data = state;
    const { result: content } = useContent({
        targetSchema: ConsentContentSchema,
        applicationMode: data?.applicationMode,
        pageName: "consent",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!data) {
        return null;
    }

    const handleClickBack = () => {
        navigate("/app/review");
    };

    const handleClickNext: ConsentFormSubmitCallback = (submittedData) => {
        dispatch({
            type: "AGREE_TO_CONSENT_ANSWERED",
            agreeToConsent: submittedData.agreeToConsent,
        });

        navigate("/app/processing");
    };

    return (
        <SplitColumn>
            {content && (
                <>
                    <InfoPanel>
                        <H1>{content.leftPanelTitle}</H1>
                        <section {...setRawHtml(content.leftPanelBodyHtml)} />
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        <ConsentForm
                            firstName={state.application!.firstName!}
                            initialValues={{
                                agreeToConsent: state.application!.agreeToConsent,
                            }}
                            onSubmit={handleClickNext}
                            content={content}
                        />
                        <ButtonGroup>
                            <Button label={content.previousLabel} onClick={handleClickBack} />
                            <Button label={content.nextLabel} type="submit" color="primary" form="consent-form" />
                        </ButtonGroup>
                    </PageDetails>
                </>
            )}
        </SplitColumn>
    );
};

export default Consent;
