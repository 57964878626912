import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SplitColumn, PageDetails, InfoPanel } from "layout";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import PHONE_TYPE_SELECT_OPTIONS from "constants/phoneTypeSelectOptions";
import { H1, H2 } from "components/text";
import { setRawHtml } from "content/setRawHtml";
import { z } from "zod";
import { contentReplacer } from "utils/contentReplacer";
import { ApplicationModeEnumSchema, makeBaseApplicationSchema } from "state/ApplicationStateSchema";
import { DTOPageEnum } from "constants/pageNames";
import DirectTermPersonalInfoContainer from "./DirectTermPersonalInfoContainer";
import LoyaltyPersonalInfoContainer from "./LoyaltyPersonalInfoContainer";
import { PersonalInfoContentSchema } from "content/contentSchemas";
import { useContent } from "hooks/useContent";

const phoneTypesArray: Readonly<string[]> = PHONE_TYPE_SELECT_OPTIONS.map((type) => type.value);

const ApplicationStep2Schema = z.object({
    applicantType: z.enum(["member", "spouse"]),
    policyNumber: z.string().nullish(),
    hannoverId: z.string().nullish(),

    selectedCoverageAmount: z.number().nullish(),
    selectedCoveragePremium: z.number().nullish(),
    selectedCoverageTier: z.string().nullish(),
    selectedCoverageType: z.enum(["Individual", "Family"]).nullish(),

    firstName: z.string().nullish(),
    middleInitial: z.string().nullish(),
    lastName: z.string().nullish(),

    dateOfBirth: z.string().regex(/^[1-2]\d{3}-[0-1][0-9]-[0-3][0-9]$/),
    // FUTURE: The gender can be modeled as optional / union or similar to QuoteResults we could model the overal state as a DU
    // controlled by the applicationMode property.
    gender: z.enum(["male", "female"]).optional(),
    zipCode: z.string().length(5),

    addressLine1: z.string().nullish(),
    addressLine2: z.string().nullish(),
    city: z.string().nullish(),
    // State will always be provided initially via the CDS service lookup
    state: z
        .string()
        .length(2)
        .regex(/^[A-Z]{2}$/),
    phone: z
        .string()
        .length(10)
        .regex(/^\d{10}$/)
        .nullish(),
    phoneType: z.enum([phoneTypesArray[0], ...phoneTypesArray.slice(1)]).nullish(), // Non-empty array hack
    email: z.string().nullish(),
    height: z
        .object({
            feet: z.number().int(),
            inches: z.number().int(),
        })
        .nullish(),
    weight: z.number().int().gte(0).lte(999).nullish(),
    willReplacePolicy: z.boolean().nullish(),
    policyToReplace: z
        .object({
            company: z.string().nullish(),
            policyNumber: z.string().nullish(),
        })
        .nullish(),
});

const PersonalInfoState = makeBaseApplicationSchema(
    ApplicationStep2Schema,
    z.object({
        applicationMode: ApplicationModeEnumSchema,
    })
);

const pageName = DTOPageEnum.enum.personalInfo;

const PersonalInfo = () => {
    usePageTitle("Personal Info | AAA Life");
    useSendInteraction(pageName);

    const { state, dispatch } = useFormState(pageName);
    const navigate = useNavigate();
    const parseResult = PersonalInfoState.safeParse(state);

    useEffect(() => {
        if (!parseResult.success) {
            console.error(parseResult.error);
            navigate("/system-error");
        }
    });

    const { result: content } = useContent({
        targetSchema: PersonalInfoContentSchema,
        applicationMode: state.applicationMode,
        pageName: "personal-info",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!parseResult.success) {
        return null;
    }

    const handleClickBack = () => {
        navigate("/quote/results");
    };

    const handleClickNext = () => {
        navigate("/app/beneficiaries");
    };

    const onError = (formError: unknown) => {
        if (formError instanceof Error) {
            navigate("/system-error");
        }
    };

    const applicationData = parseResult.data.application;

    const interpolatedLeftPanelBodyHtml = contentReplacer(
        { name: applicationData.firstName ?? undefined },
        content?.leftPanelBodyHtml
    );

    return (
        <SplitColumn>
            {content && (
                <>
                    <InfoPanel>
                        <H1>{content.leftPanelTitle}</H1>
                        <section {...setRawHtml(interpolatedLeftPanelBodyHtml)} />
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        {state.applicationMode === "directterm" ? (
                            <DirectTermPersonalInfoContainer
                                content={content}
                                applicationData={applicationData}
                                dispatch={dispatch}
                                onError={onError}
                                handleClickBack={handleClickBack}
                                handleClickNext={handleClickNext}
                            />
                        ) : (
                            <LoyaltyPersonalInfoContainer
                                content={content}
                                applicationData={applicationData}
                                dispatch={dispatch}
                                onError={onError}
                                handleClickBack={handleClickBack}
                                handleClickNext={handleClickNext}
                            />
                        )}
                    </PageDetails>
                </>
            )}
        </SplitColumn>
    );
};

export default PersonalInfo;
