import { z } from "zod";
import {
    ApplicantTypeEnum,
    BeneficiarySchema,
    BenefitOptionSchema,
    HealthQuestionsSchema,
    OfferStringSchema,
    PersonalInfoSchema,
    PolicyStatusSchema,
    QuoteOptionSchema,
    SecondaryAddresseeSchema,
    SelectedCoverageSchema,
} from "state/ApplicationStateSchema";
import { GendersSchema } from "./common/Genders";

const CoverageOptionsSchema = z.union([QuoteOptionSchema, BenefitOptionSchema]).array();
export type CoverageOptions = z.infer<typeof CoverageOptionsSchema>;

export const ActionTypeEnum = z.enum([
    "LANDING_PAGE_VISITED",
    "OFFER_FOUND",
    "DIRECTTERM_QUOTES_FOUND",
    "LOYALTY_QUOTES_FOUND",
    "CLUB_IDENTIFIED",
    "COVERAGE_SELECTED",
    "PERSONAL_INFO_VERIFIED",
    "BENEFICIARY_SELECTED",
    "SECONDARY_ADDRESSEE_SUBMITTED",
    "HEALTH_QUESTIONS_ANSWERED",
    "PAYMENT_TOKEN_RECEIVED",
    "PAYMENT_TOKEN_RECEIVED",
    "AGREE_TO_CONSENT_ANSWERED",
    "APPLICATION_PROCESSED",
    "APPLICATION_COMPLETED",
]);

const LandingPageVisitedActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.LANDING_PAGE_VISITED),
    url: z.string(),
    params: z.map(z.string(), z.string()),
});

export type LandingPageVisitedAction = Readonly<z.infer<typeof LandingPageVisitedActionSchema>>;

const OfferFoundActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.OFFER_FOUND),

    invitationCode: z.string(),
    offer: OfferStringSchema.array(),
    planCode: z.string(),
    firstName: z.string(),
    middleInitial: z.string().optional(),
    lastName: z.string(),
    keyCode: z.string(),
    aaaMemberNumber: z.string(),
    memberOfferAvailable: z.boolean(),
    spouseOfferAvailable: z.boolean(),
    membershipLength: z.number().optional(),
    memberSince: z.string().optional(),
    gender: GendersSchema.optional(),
});
export type OfferFoundAction = z.infer<typeof OfferFoundActionSchema>;

const DirectTermQuotesFoundActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.DIRECTTERM_QUOTES_FOUND),

    applicantType: ApplicantTypeEnum,
    coverageOptions: QuoteOptionSchema.array(),
    gender: GendersSchema,
    dateOfBirth: z.string(),
    zipCode: z.string(),
    email: z.string().optional(),
    hasUsedNicotineLastYear: z.boolean(),
});
export type DirectTermQuotesFoundAction = z.infer<typeof DirectTermQuotesFoundActionSchema>;

const LoyaltyTravelAccidentQuotesFoundActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.LOYALTY_QUOTES_FOUND),

    applicantType: z.literal(ApplicantTypeEnum.Enum.member),
    coverageOptions: BenefitOptionSchema.array(),
    dateOfBirth: z.string(),
    zipCode: z.string(),
    email: z.string().optional(),
    membershipLevel: z.number(),
});
export type LoyaltyTravelAccidentQuotesFoundAction = z.infer<typeof LoyaltyTravelAccidentQuotesFoundActionSchema>;

const QuotesFoundActionSchema = z.union([DirectTermQuotesFoundActionSchema, LoyaltyTravelAccidentQuotesFoundActionSchema]);
export type QuotesFoundAction = z.infer<typeof QuotesFoundActionSchema>;

const ClubIdentifiedActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.CLUB_IDENTIFIED),
    state: z.string(),
    clubCode: z.string(),
    planCode: z.string(),
});
export type ClubIdentifiedAction = z.infer<typeof ClubIdentifiedActionSchema>;

const CoverageSelectedActionSchema = SelectedCoverageSchema.extend({
    type: z.literal(ActionTypeEnum.enum.COVERAGE_SELECTED),
});
export type CoverageSelectedAction = z.infer<typeof CoverageSelectedActionSchema>;

const PersonalInfoVerifiedActionSchema = PersonalInfoSchema.extend({
    type: z.literal(ActionTypeEnum.enum.PERSONAL_INFO_VERIFIED),
    // First middle, and last for spouse values
    firstName: z.string(),
    middleInitial: z.string().nullish(),
    lastName: z.string(),

    // Override hannoverId as optional since it won't be required for loyalty
    hannoverId: z.string().optional(),
});
export type PersonalInfoVerifiedAction = z.infer<typeof PersonalInfoVerifiedActionSchema>;

const BeneficiarySelectedActionSchema = BeneficiarySchema.extend({
    type: z.literal(ActionTypeEnum.enum.BENEFICIARY_SELECTED),
});
export type BeneficiarySelectedAction = z.infer<typeof BeneficiarySelectedActionSchema>;

const SecondaryAddresseeSubmittedActionSchema = SecondaryAddresseeSchema.extend({
    type: z.literal(ActionTypeEnum.enum.SECONDARY_ADDRESSEE_SUBMITTED),
    designatedSecondaryAddressee: z.boolean(),
});
export type SecondaryAddresseeSubmittedAction = z.infer<typeof SecondaryAddresseeSubmittedActionSchema>;

const HealthQuestionsAnsweredActionSchema = HealthQuestionsSchema.extend({
    type: z.literal(ActionTypeEnum.enum.HEALTH_QUESTIONS_ANSWERED),
});
export type HealthQuestionsAnsweredAction = z.infer<typeof HealthQuestionsAnsweredActionSchema>;

const PaymentTokenReceivedActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.PAYMENT_TOKEN_RECEIVED),
    paymentToken: z.string(),
});
export type PaymentTokenReceivedAction = z.infer<typeof PaymentTokenReceivedActionSchema>;

const AgreeToConsentAnsweredActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.AGREE_TO_CONSENT_ANSWERED),
    agreeToConsent: z.boolean(),
});
export type AgreeToConsentAnsweredAction = z.infer<typeof AgreeToConsentAnsweredActionSchema>;

const ApplicationProcessedActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.APPLICATION_PROCESSED),
    policyStatus: PolicyStatusSchema,
});
export type ApplicationProcessedAction = z.infer<typeof ApplicationProcessedActionSchema>;

const ApplicationCompletedActionSchema = z.object({
    type: z.literal(ActionTypeEnum.enum.APPLICATION_COMPLETED),
});
export type ApplicationCompletedAction = z.infer<typeof ApplicationCompletedActionSchema>;

export const ReducerActionSchema = z.discriminatedUnion("type", [
    LandingPageVisitedActionSchema,
    OfferFoundActionSchema,
    DirectTermQuotesFoundActionSchema,
    LoyaltyTravelAccidentQuotesFoundActionSchema,
    ClubIdentifiedActionSchema,
    CoverageSelectedActionSchema,
    PersonalInfoVerifiedActionSchema,
    BeneficiarySelectedActionSchema,
    SecondaryAddresseeSubmittedActionSchema,
    HealthQuestionsAnsweredActionSchema,
    PaymentTokenReceivedActionSchema,
    AgreeToConsentAnsweredActionSchema,
    ApplicationProcessedActionSchema,
    ApplicationCompletedActionSchema,
]);
export type ReducerActions = z.infer<typeof ReducerActionSchema>;
