import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { SplitColumn, PageDetails, ButtonGroup, InfoPanel } from "layout";
import { useFormState, usePageTitle, useSendInteraction } from "hooks";
import Button from "components/inputs/button/Button";
import { H1, H2 } from "components/text";
import AddresseeForm, { AddresseeFields } from "features/forms/addressee-form/AddresseeForm";
import { setRawHtml } from "content/setRawHtml";
import { emptyToUndefined, toBooleanFromLowerCaseYesNo } from "utils/conversions";
import { parseFormatted10DigitPhone } from "utils/phone";
import { ApplicationModeEnumSchema, makeBaseApplicationSchema } from "state/ApplicationStateSchema";
import { DTOPageEnum } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { AddresseeContentSchema } from "content/contentSchemas";

const ApplicationStep5Schema = z.object({
    designatedSecondaryAddressee: z.boolean().optional(),
    secondaryAddressee: z
        .object({
            firstName: z.string().optional(),
            lastName: z.string().optional(),
            addressLine1: z.string().optional(),
            addressLine2: z.string().optional(),
            city: z.string().optional(),
            state: z.string().optional(),
            zipCode: z.string().optional(),
            phone: z.string().optional(),
        })
        .optional(),
});

const SecondaryAddresseeState = makeBaseApplicationSchema(
    ApplicationStep5Schema,
    z.object({
        applicationMode: ApplicationModeEnumSchema,
    })
);

const pageName = DTOPageEnum.enum.secondaryAddressee;

const Addressee = () => {
    usePageTitle("Addressee | AAA Life");
    useSendInteraction(pageName);

    const { state, dispatch } = useFormState(pageName);
    const navigate = useNavigate();
    const parseResults = SecondaryAddresseeState.safeParse(state);

    useEffect(() => {
        if (!parseResults.success) {
            console.error(parseResults.error);
            navigate("/system-error");
        }
    }, [parseResults, navigate]);

    const { result: content } = useContent({
        targetSchema: AddresseeContentSchema,
        applicationMode: state?.applicationMode,
        pageName: "addressee",
        clubCode: state?.clubSpecificData?.clubCode ?? state?.campaign?.clubCode ?? undefined,
        state: state?.application?.state ?? state?.clubSpecificData?.state,
    });

    if (!parseResults.success) {
        return null;
    }

    const applicationData = parseResults.data.application;

    const handleClickBack = () => {
        navigate("/app/beneficiaries");
    };

    const handleClickNext = (submittedData: AddresseeFields) => {
        dispatch({
            type: "SECONDARY_ADDRESSEE_SUBMITTED",
            designatedSecondaryAddressee: !!toBooleanFromLowerCaseYesNo(submittedData.designatedSecondaryAddressee),
            firstName: submittedData.firstName,
            lastName: submittedData.lastName,
            phone: emptyToUndefined(parseFormatted10DigitPhone(submittedData.phone || "")),
            addressLine1: submittedData.addressLine1,
            addressLine2: submittedData.addressLine2 && emptyToUndefined(submittedData.addressLine2),
            city: submittedData.city,
            state: submittedData.state,
            zipCode: submittedData.zipCode,
        });
        if (state.applicationMode === "loyalty") {
            navigate("/app/payment-info");
        } else {
            navigate("/app/health-statement");
        }
    };

    return (
        <SplitColumn>
            {content && (
                <>
                    <InfoPanel>
                        <H1>{content.leftPanelTitle}</H1>
                        <section {...setRawHtml(content.leftPanelBodyHtml)} />
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        <AddresseeForm
                            initialValues={{
                                designatedSecondaryAddressee: applicationData.designatedSecondaryAddressee,
                                firstName: applicationData.secondaryAddressee?.firstName,
                                lastName: applicationData.secondaryAddressee?.lastName,
                                addressLine1: applicationData.secondaryAddressee?.addressLine1,
                                addressLine2: applicationData.secondaryAddressee?.addressLine2,
                                city: applicationData.secondaryAddressee?.city,
                                state: applicationData.secondaryAddressee?.state,
                                zipCode: applicationData.secondaryAddressee?.zipCode,
                                phone: applicationData.secondaryAddressee?.phone,
                            }}
                            onSubmit={handleClickNext}
                            content={content}
                        />
                        <ButtonGroup>
                            <Button label={content.previousLabel} onClick={handleClickBack} />
                            <Button label={content.nextLabel} type="submit" color="primary" form="addressee-form" />
                        </ButtonGroup>
                    </PageDetails>
                </>
            )}
        </SplitColumn>
    );
};

export default Addressee;
