import { getDirectTermOnlineQuote } from "services/onlineApplicationQuoteService";
import { QuoteStartData } from "./QuoteStart";
import QuoteStartForm, {
    QuoteStartFormFields,
    QuoteStartFormSubmitCallback,
} from "features/forms/quote-start-form/QuoteStartForm";
import { QuoteStartContent } from "content/contentSchemas";
import { toBooleanFromLowerCaseYesNo, toUpperCaseGenderFromLowerCaseGender } from "utils/conversions";
import { QuoteOption } from "state/ApplicationStateSchema";
import { getClubData } from "services/cdsService";
import { DirectTermPlanCodes, getDirectTermPlanCode } from "domain/planCodes";
import { DISQUALIFIED_STATES } from "constants/disqualifiedStates";

export type DirectTermQuoteSubmitData = QuoteStartFormFields & {
    state: string;
    clubCode: string;
    planCode: DirectTermPlanCodes;
} & {
    coverageOptions: QuoteOption[];
};

type Props = {
    content: QuoteStartContent;
    application: QuoteStartData;
    onError: Function;
    handleDispatch: (data: DirectTermQuoteSubmitData) => void;
};

export const DirectTermQuoteStartContainer = ({ content, application, onError, handleDispatch }: Props) => {
    const applicationData = application.application;

    const initialValues = {
        applicantType: applicationData.applicantType,
        zipCode: applicationData.zipCode,
        gender: applicationData.gender,
        hasUsedNicotineLastYear: applicationData.hasUsedNicotineLastYear,
        dateOfBirth: applicationData.dateOfBirth,
    };

    const getQuotes: QuoteStartFormSubmitCallback = async function getQuotes(submittedData) {
        // Extra checks could be done here specific to direct term if not already done so in QuoteStartForm
        try {
            const planCode = getDirectTermPlanCode(submittedData.state);

            const response = await getDirectTermOnlineQuote({
                state: submittedData.state,
                zipCode: submittedData.zipCode,
                gender: toUpperCaseGenderFromLowerCaseGender(submittedData.gender!),
                useNicotine: toBooleanFromLowerCaseYesNo(submittedData.hasUsedNicotineLastYear)!,
                year: submittedData.birthDate.getFullYear().toString(),
                month: (submittedData.birthDate.getMonth() + 1).toString(),
                day: submittedData.birthDate.getDate().toString(),
                productCode: planCode,
            });

            const clubResponse = await getClubData(submittedData.zipCode);

            // We have to work check the coverageAmount and totalPremiumAmount properties because according to the
            // schema they could be null. FUTURE: We should change the service to define these as required.
            const quotes = response.quotes?.flatMap((quote) =>
                Object.values(quote).every((value) => typeof value !== "undefined") ? [quote as Required<typeof quote>] : []
            );

            if (!quotes || quotes.length < 1) {
                throw new Error("Quote result was unexpectedly missing quotes.");
            }

            handleDispatch({
                ...submittedData,
                clubCode: clubResponse.clubCode,
                planCode: planCode,
                coverageOptions: quotes.map((quote) => ({
                    coverageAmount: quote.coverageAmount,
                    monthlyPremium: quote.totalPremiumAmount,
                })),
            });
        } catch (error) {
            console.log(error);
            onError(error);
        }
    };

    return (
        <QuoteStartForm
            content={content}
            initialValues={initialValues}
            onSubmit={getQuotes}
            onError={onError}
            showEmail={false}
            showNicotine={true}
            showSpouseSelect={true}
            showGenderSelect={true}
            memberOfferAvailable={application.memberOfferAvailable}
            spouseOfferAvailable={application.spouseOfferAvailable}
            maxAge={74}
            disqualifiedStates={DISQUALIFIED_STATES["directterm"]}
        />
    );
};
